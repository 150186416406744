    .st0{filter:url(#Adobe_OpacityMaskFilter);}
	.st1{fill:url(#XMLID_650_);}
	.st2{opacity:0.5;mask:url(#fond_gradient_8_);}
	.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#C3CAE5;}
	.st4{fill:#CACDD8;}
	.st5{fill:#E2E5ED;}
	.st6{fill:#FFFFFF;}
	.st7{fill:#EBEEF7;}
	/* .st8{fill:url(#XMLID_794_);}  override by Theme */
	.st9{fill:url(#XMLID_795_);}
	.st10{fill:#9FA9BF;}
	.st11{fill:#C3CAE5;}
	/* .st12{fill:#FF4137;}  override by Theme */
	.st13{fill:#17152B;}
	.st14{filter:brightness(70%)} /* circle of handle */
	.st15{filter:brightness(80%)} /* rod of handle */
	.st16{fill:#F2BBB8;}
	.st17{fill:#9297A5;}
	.st18{fill:#E59095;}
	.st19{filter:url(#Adobe_OpacityMaskFilter_1_);}
	.st20{fill:url(#XMLID_796_);}
	.st21{opacity:0.5;mask:url(#fond_gradient_9_);}
	.st22{fill:url(#XMLID_797_);}
	.st23{fill:#74BDDE;}
	.st24{fill:#63B3CE;}
	.st25{filter:url(#Adobe_OpacityMaskFilter_2_);}
	.st26{fill:url(#XMLID_798_);}
	.st27{opacity:0.5;mask:url(#fond_gradient_10_);}
	.st28{opacity:0.35;}
	.st29{fill:url(#XMLID_799_);}
	.st30{fill:url(#XMLID_800_);}
	.st31{fill:url(#XMLID_801_);}
	.st32{fill:url(#XMLID_802_);}
	.st33{fill:#303747;}
	.st34{fill:#1B202D;}
	.st35{fill:#DEF5FF;}
	.st36{fill:#6B707C;}
	.st37{fill:#20232E;}
	.st38{fill:#F1917C;}
	.st39{fill:#FF4A2F;}
	.st40{fill:#FAFAFC;}
	.st41{fill:url(#XMLID_803_);}
	.st42{opacity:0.48;fill:#C3CAE5;}
	.st43{opacity:0.64;fill:#FFFFFF;}
	.st44{filter:url(#Adobe_OpacityMaskFilter_3_);}
	.st45{fill:url(#XMLID_804_);}
	.st46{opacity:0.5;mask:url(#fond_gradient_11_);}
	.st47{fill:url(#XMLID_805_);}
	.st48{fill:url(#XMLID_806_);}
	.st49{fill:url(#XMLID_807_);}
	.st50{fill:url(#XMLID_808_);}
	.st51{fill:#D13119;}
	.st52{fill:#495363;}
	.st53{fill:#91B5F8;}
	.st54{fill:#FFA1A1;}
	.st55{filter:url(#Adobe_OpacityMaskFilter_4_);}
	.st56{fill:url(#XMLID_809_);}
	.st57{opacity:0.5;mask:url(#fond_gradient_12_);}
	.st58{fill:#060633;}
	.st59{fill:#EA6868;}
	.st60{fill:#240D39;}
	.st61{fill:#272035;}
	.st62{fill:#3C414C;}
	.st63{fill:#575756;}
	.st64{fill:#9B9B9B;}
	.st65{fill:url(#XMLID_810_);}
	.st66{clip-path:url(#XMLID_812_);}
	.st67{clip-path:url(#XMLID_814_);}
	.st68{clip-path:url(#XMLID_816_);}
	.st69{fill:url(#XMLID_817_);}
	.st70{filter:url(#Adobe_OpacityMaskFilter_5_);}
	.st71{fill:url(#XMLID_818_);}
	.st72{opacity:0.5;mask:url(#fond_gradient_13_);}
	.st73{opacity:0.46;fill:#CACDD8;}
	.st74{fill:url(#XMLID_819_);}
	.st75{fill:url(#XMLID_820_);}
	.st76{fill:#FB9391;}
	.st77{fill:none;stroke:#7F7EA3;stroke-width:0.2;stroke-miterlimit:10;}
	.st78{fill:#0B0755;}
	.st79{fill:#7C9AF2;}
	.st80{fill:#E57A7A;}
	.st81{filter:url(#Adobe_OpacityMaskFilter_6_);}
	.st82{fill:url(#XMLID_821_);}
	.st83{opacity:0.5;mask:url(#fond_gradient_14_);}
	.st84{clip-path:url(#XMLID_825_);}
	.st85{fill:#FFAB95;}
	.st86{fill:#BEC2CC;}
	.st87{fill:#B0D882;}
	.st88{clip-path:url(#XMLID_826_);fill:#9297A5;}
	.st89{clip-path:url(#XMLID_826_);fill:#1B202D;}
	.st90{clip-path:url(#XMLID_826_);fill:#FF4137;}
	.st91{clip-path:url(#XMLID_826_);}
	.st92{filter:url(#Adobe_OpacityMaskFilter_7_);}
	.st93{fill:url(#XMLID_827_);}
	.st94{opacity:0.5;mask:url(#fond_gradient_15_);}
	.st95{fill:#444A56;}
	.st96{fill:#F4CECE;}