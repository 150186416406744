body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f6fa;
  font-size: 13px;
  font-family: Mulish, sans-serif;
  line-height: 24px;
}
button {
  font-family: 'Poppins', 'sans-serif';
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
p {
  margin: 0;
}
.container {
  display: flex;
  align-items: flex-start;
}

section {
  margin-right: 300px;
  padding: 12px 300px 12px 148px;
  margin: auto;
  /* taille du header */
  margin-top: 74px;
}

.sectionAccueil {
  margin-right: 0;
  padding: 12px 148px 48px 148px;
}
.MuiOutlinedInput-input {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.MuiFormHelperText-root {
  display: none;
}

.error-message {
  text-align: right;
  width: fit-content;
  padding: 4px 12px;
  background: #f443362e;
  border: 1px solid #fdb7b2;
  border-radius: 12px;
  color: black;
}

.globalLayout h2 {
  padding-top: 16px;
}
.headerPage {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;
}

.disabled {
  display: none;
}

@keyframes item7-dot {
  0% {
    opacity: 1;
    top: 20%;
  }
  50% {
    opacity: 0.3;
    top: 0;
  }
  100% {
    opacity: 1;
    top: 20%;
  }
}

@keyframes item7-dot-small {
  0% {
    opacity: 1;
    top: 30%;
  }
  50% {
    opacity: 0.3;
    top: 0;
  }
  100% {
    opacity: 1;
    top: 30%;
  }
}

@media all and (max-width: 1200px) {
  .sectionAccueil {
    margin-right: 0;
    padding: 12px 48px 12px 48px;
  }
}
